$theme-colors: (
    "red": #FF5252,
    "gray": #B6B6B6,
    "dark": #292929,
    "white": #ffffff,
    "black": #000000,
    "ligth-gray": #efefef,  
    "dark-red": darken(#FF5252, 20%),
);

$section-fadeIn: fadeIn 2.3s 1;

// Fonts
$font-light: 100;
$font-regular: 400;
$font-bold: 700;

$font-size-14: 14px;
$font-size-16: 1rem;
$font-size-18: 1.125rem;
$font-size-24: 	1.5rem;
$font-size-50: 3.125rem;
$font-size-70: 4.375rem;
$font-size-100: 6.25rem;

$font-family-roboto: 'Roboto', monospace;
$font-family-roboto-mono: 'Roboto Mono', monospace;

$custom-letter-spacing: 0.075em;

// Media queries 
$breakpoint-tablet: 768px;
$breakpoint-desktop: 992px;

